import React from 'react';
import BackgroundImage from "gatsby-background-image";


const PreviewCompatibleBackgroundImage = ({imageInfo, ...props}) => {

    const {childImageSharp, url} = imageInfo

    if (!!childImageSharp) {
        return <BackgroundImage {...props} fluid={childImageSharp.fluid}/>
    }

    if (!!url && typeof url === 'string')

        return <div {...props} style={{backgroundImage: `url(${url})`, display: "flex"}}/>

    return null
};

export default PreviewCompatibleBackgroundImage;