import React from 'react';
import './Hero.scss';
import PropTypes from 'prop-types';
import PreviewCompatibleBackgroundImage from "../PreviewCompatibleBackgroundImage";

const Hero = ({title, subtitle, image}) => {

    return (
        <div className="page-hero full-width-image margin-top-0">
            <PreviewCompatibleBackgroundImage Tag="div" className={"page-hero__image full-width-image"} imageInfo={image} preserveStackingContext/>
            <div className="page-hero__content">
                <div className="page-hero__headers">
                    <h3 className="has-text-weight-medium is-size-6-mobile is-size-5-tablet is-size-4-widescreen">
                        {subtitle}
                    </h3>
                    <h1 className="has-text-weight-semibold is-size-5-mobile is-size-3-tablet is-size-1-widescreen has-text-centered has-text-uppercase"
                        dangerouslySetInnerHTML={{__html: title}}>
                    </h1>
                </div>
            </div>
        </div>
    );
};

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired
}

export default Hero;